import { defineComponent } from 'vue'

import type { PropType } from 'vue'

export default defineComponent({
  props: {
    type: {
      type: String as PropType<'text' | 'password'>,
      default: 'text',
    },
    label: {
      type: [String, Number],
      required: false,
      default: '',
    },
    labelAnnotation: {
      type: [String, Number],
      required: false,
      default: '',
    },
    placeholder: {
      type: String,
      required: false,
      default() {
        return ''
      },
    },
    maxLength: {
      type: Number,
      required: false,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    softDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    rightPictoClass: {
      type: String,
      required: false,
      default: 'fas fa-check',
    },
    hideRightPicto: {
      type: Boolean,
      required: true,
    },
    leftPicto: {
      type: String,
      required: false,
      default: '',
    },
    startFocused: {
      type: Boolean,
      required: false,
      default: false,
    },
    noStyling: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
})
