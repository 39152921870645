<template>
  <div class="inputTextWrapper" :class="{ error: !validity, noStyling }">
    <VLabel v-if="hasLabelSlot || !!label" class="tw-mb-2">
      <template #default>
        <slot v-if="hasLabelSlot" name="label" />
        <span v-else class="tw-font-sans"> {{ label }} {{ !!label }} </span>
      </template>
      <template #subLabel>
        {{ labelAnnotation }}
      </template>
    </VLabel>

    <div class="inputWrap">
      <slot name="input-left" />
      <div class="grooverInnerWrap" :class="{ focus }">
        <slot name="left-picto">
          <i
            v-if="leftPicto"
            class="leftPicto"
            :class="[
              leftPicto,
              {
                pictoError: !validity,
                '!tw-text-black': focus,
                '!tw-text-error': !validity,
              },
            ]"
          />
        </slot>
        <slot name="input" v-bind="{ uid }" />
        <slot name="right-picto">
          <transition name="fade" mode="out-in">
            <i
              v-if="!hideRightPicto"
              class="pictoRight"
              :class="rightPictoClass"
            />
          </transition>
        </slot>
      </div>
      <slot name="input-right" />
    </div>
    <HeightExpand>
      <div v-if="!validity && hasErrorMessageSlot" class="errorHolder">
        <VText cfg="sans/13/medium" color="red-500" html-tag="p">
          <slot name="err-message" />
        </VText>
      </div>
    </HeightExpand>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import propsMixin from './props'

import HeightExpand from '~/components/transitions/heightExpand.vue'
import VLabel from '~/components/ui/VLabel.vue'

import { hasSlotContent } from '~/utils/hasSlot'

export default defineComponent({
  components: {
    HeightExpand,
    VLabel,
  },
  mixins: [propsMixin],
  props: {
    validity: {
      type: Boolean,
      required: false,
    },
    isDisabled: {
      type: Boolean,
      required: true,
    },
    hideRightPicto: {
      type: Boolean,
      required: true,
    },
    focus: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    return {
      uid: useId(),
    }
  },
  computed: {
    hasLabelSlot() {
      return hasSlotContent(this.$slots.label)
    },
    hasErrorMessageSlot() {
      return hasSlotContent(this.$slots['err-message'])
    },
  },
})
</script>
